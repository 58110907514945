<template>
  <div class='container'></div>
</template>

<script>
export default {
  data(){
    return{
      token: this.$route.query.token
    }
  },
  created(){
    console.log('query token',this.$route.query.token);
    this.setToken()
  },
  methods:{
    async setToken(){
      await this.$store.dispatch('wxSetToken', this.token)
    }
  }
}
</script>
<style lang='less' scoped>
</style>